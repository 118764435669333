<template>
  <div style="width: 100%" v-if="question">
    <GeoGuesserQuestion
      v-if="question.questionType == 'geo'"
      v-bind:question="question"
      v-bind:category="category"
      v-bind:quiz="quiz"
      v-bind:player="player"
      v-bind:winningTeam="winningTeam"
      v-on:didUpdateUserAnswer="updateAnswer"
    />
    <GuessQuestion
      v-bind:question="question"
      v-bind:category="category"
      v-bind:quiz="quiz"
      v-bind:player="player"
      v-bind:winningTeam="winningTeam"
      v-on:didUpdateUserAnswer="updateAnswer"
      v-else-if="question.questionType == 'guess'"
    />
    <DefaultQuestion
      v-bind:question="question"
      v-bind:category="category"
      v-bind:quiz="quiz"
      v-bind:player="player"
      v-bind:winningTeam="winningTeam"
      v-else
    />
    <transition name="bounce">
      <ShowWinningTeam
        v-if="quiz.activeQuestion.showWinningTeam"
        v-bind:quiz="quiz"
        v-bind:winningTeam="winningTeam"
      />
    </transition>
  </div>
</template>

<script>
import GeoGuesserQuestion from "../components/GeoGuesserQuestion";
import DefaultQuestion from "../components/DefaultQuestion";
import ShowWinningTeam from "../components/ShowWinningTeam";
import GuessQuestion from "../components/GuessQuestion";
export default {
  name: "ShowQuestion",
  props: ["quiz", "player"],
  components: { GeoGuesserQuestion, DefaultQuestion, ShowWinningTeam, GuessQuestion },
  methods: {
    updateAnswer(answer) {
      var questionInstance = this.question;
      questionInstance.answers = questionInstance.answers.filter(
        (x) => x.playerId != this.player.playerId
      );
      questionInstance.answers.push(answer);
      this.$emit("didUpdateAnswer");
    },
  },
  computed: {
    question() {
      return this.quiz.questions.find(
        (x) => x.questionId == this.quiz.activeQuestion.questionId
      );
    },
    category() {
      return this.quiz.categories.find(
        (x) => x.categoryId == this.question.categoryId
      );
    },

    winningTeam() {
      if (!this.question.winnerTeamId) {
        return null;
      }
      return this.quiz.teams.find(
        (x) => x.teamId == this.question.winnerTeamId
      );
    },
  },
};
</script>
