<template>
  <v-dialog
    v-model="dialog"
    class="addplayer-dialog"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Wer biste?</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                autofocus
                label="Dein Name"
                v-model="playerName"
                required
                @keyup.native.enter="add"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <div class="d-flex flex-wrap">
                <v-select
                  :items="avatars"
                  label="Avatar"
                  v-model="selectedAvatar"
                >
                  <template v-slot:selection="{ item }">
                    <Avatar
                      :avatar="item"
                      class="avatar-selection"
                      :key="item"
                    />
                  </template>
                  <template v-slot:item="{ item }">
                    <Avatar
                      :avatar="item"
                      class="avatar-selection ma-2"
                      :key="item"
                    />
                  </template>
                </v-select>
              </div>
            </v-col>
            <v-col cols="12">
              <div class="d-flex flex-wrap" v-if="quiz.teams.length > 0">
                <v-select
                  :items="quiz.teams"
                  label="Für welches Team spielst du?"
                  v-model="selectedTeamId"
                  item-text="name"
                  item-value="teamId"
                >
                </v-select>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" type="submit" @click="add" :disabled="!valid">
          Los geht's...
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import allAvatars from "../components/allAvatars";
import Avatar from "../components/Avatar";

export default {
  name: "AddPlayer",
  components: { Avatar },
  props: ["quiz"],
  data() {
    return {
      dialog: false,
      player: undefined,
      playerName: "",
      selectedAvatar: "01-mexican.svg",
      avatars: allAvatars,
      selectedTeamId: undefined,
      playerId: undefined,
    };
  },

  methods: {
    open(playerId) {
      this.playerId = playerId;
      if (!this.selectedAvatar) {
        this.selectedAvatar = this.avatars[
          Math.floor(Math.random() * this.avatars.length)
        ];
      }
      this.dialog = true;
    },
    close() {
      this.playerId = undefined;
      this.playerName = "";
      this.selectedAvatar = undefined;
      this.selectedTeamId = undefined;
      this.dialog = false;
    },
    add() {
      var player = {
        playerId: this.playerId,
        name: this.playerName,
        avatar: this.selectedAvatar,
        teamId: this.selectedTeamId,
        online: true,
      };
      this.$emit("didAddPlayer", player);
      this.close();
    },
  },

  computed: {
    valid() {
      return (
        this.playerName && (this.quiz.teams.length == 0 || this.selectedTeamId)
      );
    },
  },
};
</script>

<style scoped>
.avatar-selection {
  margin: 10px;
}

.addplayer-dialog {
  z-index: 99999;
}
</style>
