<template>
  <div class="show-question">
    <transition name="bounce">
      <h1 class="text-center">{{ category.name }} - {{ question.points }}</h1>
    </transition>

    <transition name="bounce">
      <div
        class="d-block question-title mt-5 mx-auto pa-10"
        v-if="quiz.activeQuestion.showText"
      >
        {{ question.title }}
      </div>
    </transition>

    <transition name="bounce">
      <div
        class="answer-container d-flex justify-center align-center flex-column"
        v-if="quiz.activeQuestion.showAnswers"
      >
        <div class="answer-label">Antwort:</div>
        <div class="d-block mt-5 mx-auto pa-10 answer-title ">
          {{ question.meta.answer }}
        </div>
        <div class="d-flex justify-space-around" style="width: 100%;">
          <v-card v-for="team in quiz.teams" :key="team.id" elevation="2" class="" width="250">
            <v-list>
              <v-subheader class="teamName">{{team.name}}</v-subheader>
              <v-list-item-group v-for="answer in answersForTeam(team)" :key="answer.playerId">
                  <div class="d-flex justify-space-between answer-list-item align-center mr-4">

                    <div class="d-flex justify-space-between align-center">
                      <Avatar
                          size="sm"
                          class="mr-2 ml-4"
                          v-bind:avatar="playerWithId(answer.playerId).avatar"
                          v-bind:color="teamWithId(playerWithId(answer.playerId).teamId).color"
                        />
                      <span class="playername">{{playerWithId(answer.playerId).name}}</span>
                    </div>

                    <span> {{answer.value.value}}</span>
                  </div>
              </v-list-item-group>
            </v-list>
          </v-card>
        </div>
      </div>
    </transition>

    <transition name="bounce">
        <div class="choose-answer-container d-flex justify-center align-center flex-row pl-5 pr-5" v-if="quiz.activeQuestion.allowAnswers">
          <h3 class="mr-5">Antwort:</h3>
          <template v-if="editing">
            <v-text-field type="number" v-model.number="answer" autofocus class="mr-5"/>
            <v-btn @click="save" color="primary" x-large  elevation="2" :disabled="!answer"><v-icon>mdi-check</v-icon></v-btn>
          </template>
          <template v-else>
            <h3 class="mr-5">{{answer || '-'}}</h3>
            <v-btn @click="editing=true" elevation="2"><v-icon>mdi-pen</v-icon></v-btn>
          </template>
        </div>
    </transition>

  </div>
</template>

<script>
import Avatar from "../components/Avatar";
export default {
  name: 'GuessQuestion',
  props: ["question", "quiz", "player", "category", "winningTeam"],
  components: {Avatar},
  data() {
    return {
      answer: undefined,
      editing: true,
    }
  },
  methods: {
    save() {
       this.$emit("didUpdateUserAnswer", {
        playerId: this.player.playerId,
        value: {
          value: this.answer,
        },
      });
      this.editing = false;
    },
    playerWithId(playerId) {
      return this.quiz.players.find((x) => x.playerId == playerId);
    },
    teamWithId(teamId) {
      return this.quiz.teams.find((x) => x.teamId == teamId);
    },
  },
  computed: {
    answersForTeam() {
      return (team) => {
        var players = this.quiz.players
          .filter((x) => x.teamId == team.teamId)
          .map((x) => x.playerId);
        var answers = this.question.answers.filter((x) =>
          players.includes(x.playerId)
        ).sort((x,y) => x.value.value - y.value.value);
        return answers;
      }
    },
  }

}
</script>


<style scoped lang="scss">
.question-title {
  font-size: 2.5rem;
  text-align: center;
  @media (max-width: 700px) {
    font-size: 2rem;
  }
}
.show-question {
  width: 100%;
}

.answer-container {
  position: fixed;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.98);
  font-size: 2.5em;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.teamName {
  font-size: 1.5rem;
  font-weight: bold;
}
.answer-list-item {
  font-size: 1.5rem;
}

.answer-title {
  font-size: 3.5rem;
  font-weight: 600;
}
.answer-label {
  font-size: 2rem;
  font-weight: 600;
}
</style>