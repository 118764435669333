var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"full-page"},[_c('h1',{staticClass:"category"},[_vm._v(_vm._s(_vm.category.name)+" - "+_vm._s(_vm.question.points))]),_c('GmapMap',{ref:"gmap",staticStyle:{"width":"100%","height":"100%"},attrs:{"center":_vm.center,"zoom":10,"options":_vm.options,"map-type-id":"roadmap"}},[(_vm.quiz.activeQuestion.allowAnswers)?_c('gmap-marker',{attrs:{"position":_vm.answerCenter,"draggable":true},on:{"dragend":_vm.updateCoordinates}}):_vm._e(),(_vm.quiz.activeQuestion.showAnswers)?[_vm._l((_vm.question.answers),function(answer){return _c('gmap-custom-marker',{key:answer.playerId,attrs:{"marker":answer.value,"z-index":parseInt(10)},nativeOn:{"click":function($event){return _vm.openAnswer(answer)}}},[(
            _vm.playerWithId(answer.playerId) &&
              _vm.teamForPlayer(_vm.playerWithId(answer.playerId))
          )?[_c('Avatar',{attrs:{"avatar":_vm.playerWithId(answer.playerId).avatar,"color":_vm.teamForPlayer(_vm.playerWithId(answer.playerId)).color}}),_c('gmap-info-window',{attrs:{"opened":_vm.openAnswers.includes(answer.playerId),"position":answer.value,"options":{
              pixelOffset: {
                width: 0,
                height: -35,
              },
            }},on:{"closeclick":function($event){return _vm.closeAnswer(answer)}}},[_c('div',{staticClass:"marker-details mx-auto"},[_vm._v(" "+_vm._s(_vm.playerWithId(answer.playerId).name)+": "+_vm._s(Math.round(answer.value.distance * 100) / 100)+" km ")])])]:_vm._e()],2)}),_c('gmap-custom-marker',{attrs:{"marker":_vm.targetLocation,"z-index":parseInt(100)}},[_c('div',{staticClass:"marker-target"},[_c('v-icon',[_vm._v("mdi-crosshairs-gps")])],1)])]:_vm._e()],2),(_vm.quiz.activeQuestion.allowAnswers)?_c('div',{staticClass:"center-button-container"},[_c('v-btn',{on:{"click":_vm.useCurrentCenter}},[_vm._v("Antwort auf Zentrum setzen")])],1):_vm._e(),_c('transition',{attrs:{"name":"bounce"}},[(_vm.quiz.activeQuestion.showText)?_c('div',{staticClass:"question"},[_c('v-row',{staticClass:"max-size white lighten-3",attrs:{"align":"center","justify":"center"}},[(_vm.isImageTitle)?[_c('img',{staticClass:"pa-4",attrs:{"src":_vm.question.title}})]:[_c('div',{staticClass:"d-block"},[_vm._v(_vm._s(_vm.question.title))])]],2)],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }