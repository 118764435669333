<template>
  <div> 
    <div class="blur-background"></div>
    <div class="d-flex winning-container" fullwidth>
      <Confetti v-if="winningTeam != null" />

      <v-row align="center" justify="center">
        <transition name="bounce">
          <h1
            class="winner-display text-center"
            :class="winningTeam.color"
            v-if="winningTeam != null"
          >
            <span class="medal">🥇</span> {{ winningTeam.name }}
          </h1>
          <h1 class="winner-display text-center grey lighten-3" v-else>
            Kein Gewinner <span class="medal">😔</span>
          </h1>
        </transition>
      </v-row>
    </div>
  </div>
</template>

<script>
import Confetti from "../components/Confetti";
export default {
  name: "ShowWinningTeam",
  props: ["quiz", "winningTeam"],
  components: { Confetti },
  computed: {},
};
</script>

<style scoped lang="scss">
.winning-container {
  position: absolute;
  bottom: 0em;
  left: 0;
  right: 0em;
  top: 0;
  z-index: 10000;
}
.blur-background { 
  position: absolute;
  bottom: 0em;
  left: 0;
  right: 0em;
  top: 0;
  z-index: 9999;
  background-color: rgba(255,255,255,0.8);
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.winner-display {
  width: 100%;
  padding: 0.5em 0;
  z-index: 100;
  font-size: 3rem;
  @media (max-width: 700px) {
    font-size: 2.5rem;
  }
  .medal {
    font-size: 0.5em;
    vertical-align: middle;
  }
}
</style>
