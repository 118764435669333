<template>
  <v-dialog v-model="isActive" persistent max-width="600px">
    <v-card v-if="this.quiz.randomPlayer != undefined">
      <v-card-text>
        <v-container>
          <v-row align="center" justify="center">
            <v-col class="d-flex flex-row justify-center align-center mt-5">
              <h1>Zufallsgenerator</h1>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" v-if="this.player">
            <v-col class="d-flex flex-row justify-center align-center mt-5">
              <Avatar :avatar="this.player.avatar" class="mr-5" />
              <h1 class="player-name">{{ this.player.name }}</h1>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Avatar from "../components/Avatar";

export default {
  name: "AddPlayer",
  components: { Avatar },
  props: ["quiz", "randomPlayer"],
  data() {
    return {
      count: 20,
      player: undefined,
      interval: undefined,
    };
  },

  mounted() {
    if (this.isActive) {
      this.player = this.randomPlayer;
    }
  },
  methods: {
    chooseRandomPlayer() {
      var players = this.quiz.players.filter(
        (x) => x.teamId == this.quiz.randomPlayer.teamId
      );
      return players[Math.floor(Math.random() * players.length)];
    },
  },

  computed: {
    isActive() {
      return this.quiz && "randomPlayer" in this.quiz;
    },
  },

  watch: {
    randomPlayer(value) {
      if (value) {
        if (this.interval) {
          clearInterval(this.interval);
          this.count = 20;
        }
        this.interval = setInterval(() => {
          if (this.count === 0) {
            this.player = this.randomPlayer;
            clearInterval(this.interval);
          } else {
            this.player = this.chooseRandomPlayer();
            this.count -= 1;
          }
        }, 100);
      }
    },
  },
};
</script>

<style scoped>
.player-name {
  font-weight: 600;
  font-size: 2em;
}
</style>
