<template>
  <div class="d-flex gameboard flex-wrap">
    <div
      v-for="category in quiz.categories"
      :key="category.categoryId"
      class="mr-2 ml-2 mb-5"
    >
      <v-card
        class="mb-5 "
        max-width="300"
        min-width="100"
        elevation="2"
        :color="category.color"
        :dark="$isDarkColor(category.color)"
      >
        <v-card-title class="category-title justify-center pa-1 pa-md-4 mb-5">
          {{ category.name }}
        </v-card-title>
      </v-card>
      <v-card
        :elevation="'winnerTeamId' in question ? 0 : 2"
        v-for="(question, index) in questionsForCategory(category)"
        :key="question.questionId"
        :color="colorForQuestion(question, category, index)"
        :style="`opacity: ${question.winnerTeamId ? '0.4' : '1'};`"
        class="mb-2"
      >
        <v-card-title class="display-title justify-center pa-1 pa-md-4">
          <span v-if="'winnerTeamId' in question" style="opacity:0">-</span>
          <template v-else>{{ question.points }}</template>
        </v-card-title>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "Gameboard",
  props: ["quiz"],
  methods: {
    colorForQuestion(question, category, index) {
      if ("winnerTeamId" in question) {
        if (question.winnerTeamId !== null) {
          return `${this.teamColor(question.winnerTeamId)} lighten-2`;
        }
        return `grey lighten-4`;
      }
      return `${category.color} lighten-${Math.min(5, index)}`;
    },
    teamColor(teamId) {
      return this.quiz.teams.filter((x) => x.teamId == teamId)[0].color;
    },
  },
  computed: {
    questionsForCategory() {
      return (category) =>
        this.quiz.questions
          .filter((x) => x.categoryId == category.categoryId)
          .sort((x, y) => y.points - x.points);
    },
  },
};
</script>

<style scoped lang="scss">
.category-title {
  font-weight: 600;
}
.custom .gameboard .v-toolbar__title,
.custom .gameboard .display-title {
  font-size: 1em;

  @media (max-width: 500px) {
    font-size: 0.75em !important;
  }
}
</style>
