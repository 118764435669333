<template>
  <v-container fill-height fluid class="x-ctn" v-if="quiz">
    <v-row align="center" justify="center">
      <transition name="bounce">
        <template v-if="quiz.activeQuestion">
          <ShowQuestion
            v-bind:quiz="quiz"
            v-bind:player="getPlayer(playerId)"
            v-on:didUpdateAnswer="updateGame"
          />
        </template>
      </transition>
      <template v-if="!quiz.activeQuestion">
        <v-col cols="12" class="mb-4">
          <h1 class="display-1 text-center">{{ quiz.name }}</h1>
        </v-col>

        <v-col cols="12" class="d-flex flex-wrap justify-center">
          <transition name="bounce">
            <Gameboard v-bind:quiz="quiz" v-if="quiz.showBoard" />
          </transition>
        </v-col>
        <v-col cols="12" class="d-flex flex-wrap justify-center">
          <template v-for="team in quiz.teams">
            <TeamList
              :key="team.name"
              v-bind:team="team"
              v-bind:quiz="quiz"
              v-bind:showPlayers="!quiz.showBoard"
            />
          </template>
        </v-col>
      </template>
      <template>
        <transition name="bounce">
          <ShowRandomPlayer
            v-bind:quiz="quiz"
            v-bind:randomPlayer="quiz.randomPlayer"
          />
        </transition>
      </template>
    </v-row>

    <AddPlayer
      ref="addPlayer"
      v-bind:quiz="quiz"
      v-on:didAddPlayer="didUpdatePlayer"
    />
  </v-container>
</template>

<script>
import firebase from "../Firebase";
import AddPlayer from "../components/AddPlayer";
const quizes = firebase.firestore().collection("quizes");
import allAvatars from "../components/allAvatars";
import TeamList from "../components/TeamList";
import Gameboard from "../components/Gameboard";
import ShowQuestion from "../components/ShowQuestion";
import ShowRandomPlayer from "../components/ShowRandomPlayer";

export default {
  name: "Play",
  components: {
    AddPlayer,
    TeamList,
    Gameboard,
    ShowQuestion,
    ShowRandomPlayer,
  },
  data() {
    return {
      show: false,
      quizId: this.$route.params.gameId,
      ref: firebase.firestore(),
      quiz: undefined,
      avatars: allAvatars,
    };
  },
  methods: {
    didUpdatePlayer(player) {
      if (!this.getPlayer(player.playerId)) {
        this.quiz.players.push(Object.assign({}, player));
      } else {
        // update.
      }
      this.updateGame();
    },
    getPlayer(playerId) {
      const player = this.quiz.players.filter(
        (player) => player.playerId == playerId
      );
      if (player.length == 0) {
        return undefined;
      }
      return player[0];
    },
    updateGame() {
      this.ref
        .collection("quizes/")
        .doc(this.quizId)
        .set(Object.assign({}, this.quiz));
    },
  },
  computed: {
    playerId() {
      const loadedPlayerId = localStorage[`playerid-${this.quizId}`];
      if (!loadedPlayerId) {
        localStorage.setItem(
          `playerid-${this.quizId}`,
          Math.random()
            .toString(36)
            .substr(2, 6)
        );
        return localStorage[`playerid-${this.quizId}`];
      }
      return loadedPlayerId;
    },
  },
  watch: {
    quiz(newValue) {
      if (newValue) {
        if (!this.getPlayer(this.playerId)) {
          this.$nextTick(() => {
            this.$refs.addPlayer.open(this.playerId);
          });
        }
      }
    },
    quizId: {
      immediate: true,
      handler(id) {
        this.$bind("quiz", quizes.doc(id));
      },
    },
  },
};
</script>

<style></style>
