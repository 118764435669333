const allAvatars = [
  "01-mexican.svg",
  "02-man.svg",
  "03-pirates.svg",
  "04-girl.svg",
  "05-boy.svg",
  "06-chinese.svg",
  "07-man.svg",
  "08-police.svg",
  "09-french.svg",
  "10-girl.svg",
  "11-girl.svg",
  "12-arab.svg",
  "13-rock.svg",
  "14-boy.svg",
  "15-chinese.svg",
  "16-girl.svg",
  "17-nun.svg",
  "18-baby.svg",
  "19-vietnam.svg",
  "20-clown.svg",
  "21-indian.svg",
  "22-portuguese.svg",
  "23-oldster.svg",
  "24-girl.svg",
  "25-armenian.svg",
  "26-japan.svg",
  "27-ninja.svg",
  "28-soldier.svg",
  "29-girl.svg",
  "30-viking.svg",
  "31-boy.svg",
  "32-arab.svg",
  "33-indian.svg",
  "34-oldster.svg",
  "35-armenian.svg",
  "36-user.svg",
  "37-dj.svg",
  "38-girl.svg",
  "39-girl.svg",
  "40-safari.svg",
  "41-cowboy.svg",
  "42-boy.svg",
  "43-santaclause.svg",
  "44-native.svg",
  "45-doctor.svg",
  "46-russia.svg",
  "47-scientist.svg",
  "48-girl.svg",
  "49-bellboy.svg",
  "50-king.svg",
];
export default allAvatars;