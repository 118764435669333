<template>
  <div class="full-page">
    <h1 class="category">{{ category.name }} - {{ question.points }}</h1>
    <GmapMap
      ref="gmap"
      :center="center"
      :zoom="10"
      :options="options"
      map-type-id="roadmap"
      style="width:100%; height: 100%;"
    >
      <gmap-marker
        :position="answerCenter"
        :draggable="true"
        @dragend="updateCoordinates"
        v-if="quiz.activeQuestion.allowAnswers"
      >
      </gmap-marker>

      <template v-if="quiz.activeQuestion.showAnswers">
        <gmap-custom-marker
          :marker="answer.value"
          @click.native="openAnswer(answer)"
          v-for="answer in question.answers"
          :key="answer.playerId"
          :z-index="parseInt(10)"
        >
          <template
            v-if="
              playerWithId(answer.playerId) &&
                teamForPlayer(playerWithId(answer.playerId))
            "
          >
            <Avatar
              v-bind:avatar="playerWithId(answer.playerId).avatar"
              v-bind:color="teamForPlayer(playerWithId(answer.playerId)).color"
            />
            <gmap-info-window
              @closeclick="closeAnswer(answer)"
              :opened="openAnswers.includes(answer.playerId)"
              :position="answer.value"
              :options="{
                pixelOffset: {
                  width: 0,
                  height: -35,
                },
              }"
            >
              <div class="marker-details mx-auto">
                {{ playerWithId(answer.playerId).name }}:
                {{ Math.round(answer.value.distance * 100) / 100 }} km
              </div>
            </gmap-info-window>
          </template>
        </gmap-custom-marker>

        <gmap-custom-marker :marker="targetLocation" :z-index="parseInt(100)">
          <div class="marker-target">
            <v-icon>mdi-crosshairs-gps</v-icon>
          </div>
        </gmap-custom-marker>
      </template>
    </GmapMap>

    <div
      class="center-button-container"
      v-if="quiz.activeQuestion.allowAnswers"
    >
      <v-btn @click="useCurrentCenter">Antwort auf Zentrum setzen</v-btn>
    </div>
    <transition name="bounce">
      <div class="question" v-if="quiz.activeQuestion.showText">
        <v-row align="center" justify="center" class="max-size white lighten-3">
          <template v-if="isImageTitle">
            <img :src="question.title" class="pa-4" />
          </template>
          <template v-else>
            <div class="d-block">{{ question.title }}</div>
          </template>
        </v-row>
      </div>
    </transition>
  </div>
</template>

<script>
import GmapCustomMarker from "vue2-gmap-custom-marker";
import Avatar from "../components/Avatar";
export default {
  name: "GeoGuesserQuestion",
  props: ["player", "quiz", "category", "question", "winningTeam"],
  components: {
    "gmap-custom-marker": GmapCustomMarker,
    Avatar,
  },
  data() {
    return {
      window_open: false,
      center: { lat: 48.022461, lng: 8.530125 },
      openAnswers: [],
      options: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUI: false,
        styles: [
          {
            featureType: "administrative.land_parcel",
            elementType: "labels",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "labels.text",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.business",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road.local",
            elementType: "labels",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
        ],
      },
    };
  },
  methods: {
    updateCoordinates(coordinates) {
      this.updatePlayerAnswer({
        lat: coordinates.latLng.lat(),
        lng: coordinates.latLng.lng(),
      });
    },
    updatePlayerAnswer(coordinates) {
      this.$emit("didUpdateUserAnswer", {
        playerId: this.player.playerId,
        value: {
          lat: coordinates.lat,
          lng: coordinates.lng,
          distance: this.calculateDistanceToTarget(
            coordinates.lat,
            coordinates.lng
          ),
        },
      });
    },

    playerWithId(playerId) {
      return this.quiz.players.find((x) => x.playerId == playerId);
    },
    teamForPlayer(player) {
      return this.quiz.teams.find((x) => x.teamId == player.teamId);
    },
    openAnswer(answer) {
      this.openAnswers = this.openAnswers.filter((x) => x != answer.playerId);
      this.openAnswers.push(answer.playerId);
    },
    closeAnswer(answer) {
      this.openAnswers = this.openAnswers.filter((x) => x != answer.playerId);
    },

    calculateDistanceToTarget(lat, lng) {
      return this.distanceInKmBetweenEarthCoordinates(
        this.targetLocation.lat,
        this.targetLocation.lng,
        lat,
        lng
      );
    },

    toRad(value) {
      return (value * Math.PI) / 180;
    },

    distanceInKmBetweenEarthCoordinates(xlat1, lon1, xlat2, lon2) {
      var R = 6371; // km
      var dLat = this.toRad(xlat2 - xlat1);
      var dLon = this.toRad(lon2 - lon1);
      var lat1 = this.toRad(xlat1);
      var lat2 = this.toRad(xlat2);

      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) *
          Math.sin(dLon / 2) *
          Math.cos(lat1) *
          Math.cos(lat2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c;
      return d;
    },

    useCurrentCenter() {
      var center = this.$refs.gmap.$mapObject.getCenter();
      this.updatePlayerAnswer({
        lat: center.lat(),
        lng: center.lng(),
      });
    },
  },

  computed: {
    isImageTitle() {
      return this.question.title.startsWith("http");
    },
    answerCenter() {
      var answer = this.question.answers.find(
        (x) => x.playerId == this.player.playerId
      );
      if (answer) {
        return answer.value;
      }
      return { lat: 48.022461, lng: 8.530125 };
    },
    targetLocation() {
      const lng = parseFloat(this.question.meta.lng);
      const lat = parseFloat(this.question.meta.lat);
      return {
        lat: lat,
        lng: lng,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.full-page {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;

  .category {
    width: auto;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    height: 2em;
    z-index: 101;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px;
    font-size: 1em;
  }

  .distance-chip {
    font-weight: 600;
    font-family: "Helvetica Neue", "Helvetia", "Arial", sans-serif !important;
  }
  .marker-details {
    font-weight: 600;
    font-size: 1.5em;
  }
  .question {
    position: fixed;
    bottom: 1em;
    left: 0em;
    right: 1em;
    top: 0em;
    max-height: 100%;
    max-width: 100%;
    z-index: 100;
    text-align: center;

    .max-size {
      width: calc(100% + 48px);
      height: calc(100% + 48px);
      max-width: calc(100% + 48px);
      max-height: calc(100% + 48px);
    }

    .inner-container {
      width: 200px;
      height: 200px;
      resize: both;
      overflow: auto;
    }

    .image-container {
      display: flex;
      width: 100%;
      height: 100%;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }

  .center-button-container {
    position: absolute;
    left: 1em;
    bottom: 1em;
    z-index: 99999;
  }
}
.marker-target {
  width: 28px;
  height: 28px;
  background-color: #df4343e3;
  border-radius: 100%;
  flex: 1 auto;
  align-content: center;
  align-items: center;
  text-align: center;
  padding-top: 2px; 

  .v-icon {
    color: black;
  }
}
</style>
