<template>
  <div :class="{'show-question': true, 'show-image-question': isImageTitle && quiz.activeQuestion.showText }">
    <transition name="bounce">
      <h1 class="text-center">{{ category.name }} - {{ question.points }}</h1>
    </transition>

    <transition name="bounce">
      <template v-if="quiz.activeQuestion.showText">
        <template v-if="!this.isImageTitle">
          <div class="d-block question-title mt-5 mx-auto pa-10">
            {{ question.title }}
          </div>
        </template>
        <template v-else>
          <v-row align="center" justify="center" class="max-size white lighten-3">
            <div class="image-container">
              <img :src="question.title" class="pa-4" />
            </div>
          </v-row>
        </template>
      </template>
    </transition>

    <transition name="bounce">
      <div
        class="answer-container d-flex justify-center align-center flex-column"
        v-if="quiz.activeQuestion.showAnswers"
      >
        <div class="answer-label">Antwort:</div>
        <div class="d-block mt-5 mx-auto pa-10 answer-title ">
          {{ question.meta.answer }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "DefaultQuestion",
  props: ["question", "quiz", "player", "category", "winningTeam"],
  components: {},
  methods: {

  },
  computed: {
    isImageTitle() {
      console.log(this.question.title)
      return this.question.title.startsWith("http");
    },
  }
};
</script>

<style scoped lang="scss">
.question-title {
  font-size: 2.5rem;
  text-align: center;
  @media (max-width: 700px) {
    font-size: 2rem;
  }
}
.show-question {
  width: 100%;
  max-height: 100% !important;
}

.show-image-question {
  position: fixed;
  bottom: 1em;
  left: 0em;
  right: 1em;
  top: 0em;
  max-height: 100%;
  max-width: 100%;
  z-index: 100;
  text-align: center;

  .max-size {
    width: calc(100%);
    height: calc(100%);
    max-width: calc(100%);
    max-height: calc(100%);
  }

  .image-container {
    display: flex;
    width: 100%;
    height: 100%;
    padding-bottom: 1rem;
    justify-content: center;
    align-items: center;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
}

.show-question-image {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.answer-container {
  position: fixed;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.98);
  font-size: 2.5em;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.answer-title {
  font-size: 3.5rem;
  font-weight: 600;
}
.answer-label {
  font-size: 2rem;
  font-weight: 600;
}
</style>
